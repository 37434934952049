
.exam-list {
  box-sizing: border-box;
  height: 100%;
  padding: 18px 30px;
  .title-box {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 16px;
    .title {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 20px;
        background: #2dc079;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .subtitle {
    margin: 30px 0 20px 20px;
    color: #333;
  }
  .meal-name {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  ::v-deep .gutter {
    background: #f6f6f6;
  }
}
